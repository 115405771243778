import { AEMPage, EditableButton, EditableCustomImage } from '@fcamna/aem-library';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const MicrosoftTeamsNotificationSetup = (props: AnimatedNavigationProps) => {
  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/assembler-quickstart/microsoft-teams-notification-setup`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="header" />
        </PageHeader>
        <PageContent>
          <Text name="intro" />
          <Text name="p1" />
          <EditableCustomImage name="Step1Image" />
          <Text name="p2" />
          <EditableCustomImage name="Step2Image" />
          <EditableCustomImage name="Step2Image2" />
          <Text name="p3" />
          <EditableCustomImage name="Step3Image" />
          <Text name="p4" />
        </PageContent>
        <PageFooter>
          <EditableButton
            name="home"
            onClick={() => {
              props.navigateForward!(`/assembler-quickstart/congratulations`);
            }}
          />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(MicrosoftTeamsNotificationSetup);
