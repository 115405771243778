import { AEMPage, EditableButton } from '@fcamna/aem-library';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const MaintenanceAndErrorPage = (props: AnimatedNavigationProps) => {
  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/assembler-quickstart/maintenance-and-error-page`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="header" />
        </PageHeader>
        <PageContent>
          <Text name="intro" />
          <Text name="p1" />
          <Text name="p2" />
          <EditableButton
            name="msTeamsNotificationSetup"
            onClick={() => {
              props.navigateForward!(`/assembler-quickstart/microsoft-teams-notification-setup`);
            }}
          />
        </PageContent>
        <PageFooter>
          <EditableButton
            name="continue"
            onClick={() => {
              props.navigateForward!(`/assembler-quickstart/congratulations`);
            }}
          />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(MaintenanceAndErrorPage);
